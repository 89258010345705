import { render, staticRenderFns } from "./tenancy-mapping.vue?vue&type=template&id=0ff42cd5"
import script from "./tenancy-mapping.vue?vue&type=script&setup=true&lang=ts"
export * from "./tenancy-mapping.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./tenancy-mapping.vue?vue&type=style&index=0&id=0ff42cd5&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@align/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@align/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,TabBoxItem: require('/app/node_modules/@align/ui/dist/runtime/molecules/TabBox/TabBoxItem.vue').default,TabBox: require('/app/node_modules/@align/ui/dist/runtime/molecules/TabBox/TabBox.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,CompositionLayoutSingle: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@align/ui/dist/runtime/molecules/Composition/Composition.vue').default})
